import { usePathname } from "next/navigation";
import { useSearchParams } from "next/navigation";
import { useMemo } from "react";

export default function ValidatePath() {
  const pathname = usePathname();
  const pathArgs = useSearchParams().toString();

  const output = useMemo(() => {
    const id_matches = pathname.match(/^\/t\/(\d+)(?:.*?\/g\/(\d+))?/);
    const game_id =
      id_matches && id_matches.length >= 3 && id_matches[2]
        ? parseInt(id_matches[2])
        : null;
    return {
      pathname,
      isPathGames: /\/t\/\d+\/?$/.test(pathname),
      isPathGame: /\/t\/\d+\/g\/\d+.*$/.test(pathname),
      isPathReport: /\/t\/\d+\/report.*$/.test(pathname),
      isPathTeamSettings: /\/t\/\d+\/manage.*$/.test(pathname),
      team_id:
        id_matches && id_matches.length >= 2 && id_matches[1]
          ? parseInt(id_matches[1])
          : null,
      game_id,
      game_ids:
        typeof game_id === "number"
          ? [game_id]
          : [...pathArgs.matchAll(/gid=(\d+)/g)].map((match) =>
              parseInt(match[1])
            ),
    };
  }, [pathname, pathArgs]);

  return output;
}
